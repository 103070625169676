export const countries = [
  { country: "Afghanistan", emoji: "🇦🇫", capital: "Kabul" },
  { country: "Albania", emoji: "🇦🇱", capital: "Tirana" },
  { country: "Algeria", emoji: "🇩🇿", capital: "Algiers" },
  { country: "Andorra", emoji: "🇦🇩", capital: "Andorra la Vella" },
  { country: "Angola", emoji: "🇦🇴", capital: "Luanda" },
  { country: "Argentina", emoji: "🇦🇷", capital: "Buenos Aires" },
  { country: "Armenia", emoji: "🇦🇲", capital: "Yerevan" },
  { country: "Australia", emoji: "🇦🇺", capital: "Canberra" },
  { country: "Austria", emoji: "🇦🇹", capital: "Vienna" },
  { country: "Azerbaijan", emoji: "🇦🇿", capital: "Baku" },
  { country: "Bahamas", emoji: "🇧🇸", capital: "Nassau" },
  { country: "Bahrain", emoji: "🇧🇭", capital: "Manama" },
  { country: "Bangladesh", emoji: "🇧🇩", capital: "Dhaka" },
  { country: "Barbados", emoji: "🇧🇧", capital: "Bridgetown" },
  { country: "Belarus", emoji: "🇧🇾", capital: "Minsk" },
  { country: "Belgium", emoji: "🇧🇪", capital: "Brussels" },
  { country: "Belize", emoji: "🇧🇿", capital: "Belmopan" },
  { country: "Benin", emoji: "🇧🇯", capital: "Porto-Novo" },
  { country: "Bhutan", emoji: "🇧🇹", capital: "Thimphu" },
  { country: "Bolivia", emoji: "🇧🇴", capital: "La Paz" },
  { country: "Bosnia and Herzegovina", emoji: "🇧🇦", capital: "Sarajevo" },
  { country: "Botswana", emoji: "🇧🇼", capital: "Gaborone" },
  { country: "Brazil", emoji: "🇧🇷", capital: "Brasília" },
  { country: "Brunei", emoji: "🇧🇳", capital: "Bandar Seri Begawan" },
  { country: "Bulgaria", emoji: "🇧🇬", capital: "Sofia" },
  { country: "Burkina Faso", emoji: "🇧🇫", capital: "Ouagadougou" },
  { country: "Burundi", emoji: "🇧🇮", capital: "Bujumbura" },
  { country: "Cambodia", emoji: "🇰🇭", capital: "Phnom Penh" },
  { country: "Cameroon", emoji: "🇨🇲", capital: "Yaoundé" },
  { country: "Canada", emoji: "🇨🇦", capital: "Ottawa" },
  { country: "Cape Verde", emoji: "🇨🇻", capital: "Praia" },
  { country: "Central African Republic", emoji: "🇨🇫", capital: "Bangui" },
  { country: "Chad", emoji: "🇹🇩", capital: "N'Djamena" },
  { country: "Chile", emoji: "🇨🇱", capital: "Santiago" },
  { country: "China", emoji: "🇨🇳", capital: "Beijing" },
  { country: "Colombia", emoji: "🇨🇴", capital: "Bogotá" },
  { country: "Comoros", emoji: "🇰🇲", capital: "Moroni" },
  { country: "Congo, Republic of the", emoji: "🇨🇬", capital: "Brazzaville" },
  {
    country: "Congo, Democratic Republic of the",
    emoji: "🇨🇩",
    capital: "Kinshasa",
  },
  { country: "Costa Rica", emoji: "🇨🇷", capital: "San José" },
  { country: "Croatia", emoji: "🇭🇷", capital: "Zagreb" },
  { country: "Cuba", emoji: "🇨🇺", capital: "Havana" },
  { country: "Cyprus", emoji: "🇨🇾", capital: "Nicosia" },
  { country: "Czech Republic", emoji: "🇨🇿", capital: "Prague" },
  { country: "Denmark", emoji: "🇩🇰", capital: "Copenhagen" },
  { country: "Djibouti", emoji: "🇩🇯", capital: "Djibouti" },
  { country: "Dominica", emoji: "🇩🇲", capital: "Roseau" },
  { country: "Dominican Republic", emoji: "🇩🇴", capital: "Santo Domingo" },
  { country: "East Timor", emoji: "🇹🇱", capital: "Dili" },
  { country: "Ecuador", emoji: "🇪🇨", capital: "Quito" },
  { country: "Egypt", emoji: "🇪🇬", capital: "Cairo" },
  { country: "El Salvador", emoji: "🇸🇻", capital: "San Salvador" },
  { country: "Equatorial Guinea", emoji: "🇬🇶", capital: "Malabo" },
  { country: "Eritrea", emoji: "🇪🇷", capital: "Asmara" },
  { country: "Estonia", emoji: "🇪🇪", capital: "Tallinn" },
  { country: "Ethiopia", emoji: "🇪🇹", capital: "Addis Ababa" },
  { country: "Fiji", emoji: "🇫🇯", capital: "Suva" },
  { country: "Finland", emoji: "🇫🇮", capital: "Helsinki" },
  { country: "France", emoji: "🇫🇷", capital: "Paris" },
  { country: "Gabon", emoji: "🇬🇦", capital: "Libreville" },
  { country: "Gambia", emoji: "🇬🇲", capital: "Banjul" },
  { country: "Georgia", emoji: "🇬🇪", capital: "Tbilisi" },
  { country: "Germany", emoji: "🇩🇪", capital: "Berlin" },
  { country: "Ghana", emoji: "🇬🇭", capital: "Accra" },
  { country: "Greece", emoji: "🇬🇷", capital: "Athens" },
  { country: "Grenada", emoji: "🇬🇩", capital: "St. George's" },
  { country: "Guatemala", emoji: "🇬🇹", capital: "Guatemala City" },
  { country: "Guinea", emoji: "🇬🇳", capital: "Conakry" },
  { country: "Guinea-Bissau", emoji: "🇬🇼", capital: "Bissau" },
  { country: "Guyana", emoji: "🇬🇾", capital: "Georgetown" },
  { country: "Haiti", emoji: "🇭🇹", capital: "Port-au-Prince" },
  { country: "Honduras", emoji: "🇭🇳", capital: "Tegucigalpa" },
  { country: "Hungary", emoji: "🇭🇺", capital: "Budapest" },
  { country: "Iceland", emoji: "🇮🇸", capital: "Reykjavík" },
  { country: "India", emoji: "🇮🇳", capital: "New Delhi" },
  { country: "Indonesia", emoji: "🇮🇩", capital: "Jakarta" },
  { country: "Iran", emoji: "🇮🇷", capital: "Tehran" },
  { country: "Iraq", emoji: "🇮🇶", capital: "Baghdad" },
  { country: "Ireland", emoji: "🇮🇪", capital: "Dublin" },
  { country: "Israel", emoji: "🇮🇱", capital: "Jerusalem" },
  { country: "Italy", emoji: "🇮🇹", capital: "Rome" },
  { country: "Jamaica", emoji: "🇯🇲", capital: "Kingston" },
  { country: "Japan", emoji: "🇯🇵", capital: "Tokyo" },
  { country: "Jordan", emoji: "🇯🇴", capital: "Amman" },
  { country: "Kazakhstan", emoji: "🇰🇿", capital: "Nur-Sultan" },
  { country: "Kenya", emoji: "🇰🇪", capital: "Nairobi" },
  { country: "Kiribati", emoji: "🇰🇮", capital: "Tarawa" },
  { country: "Korea, North", emoji: "🇰🇵", capital: "Pyongyang" },
  { country: "Korea, South", emoji: "🇰🇷", capital: "Seoul" },
  { country: "Kosovo", emoji: "🇽🇰", capital: "Pristina" },
  { country: "Kuwait", emoji: "🇰🇼", capital: "Kuwait City" },
  { country: "Kyrgyzstan", emoji: "🇰🇬", capital: "Bishkek" },
  { country: "Laos", emoji: "🇱🇦", capital: "Vientiane" },
  { country: "Latvia", emoji: "🇱🇻", capital: "Riga" },
  { country: "Lebanon", emoji: "🇱🇧", capital: "Beirut" },
  { country: "Lesotho", emoji: "🇱🇸", capital: "Maseru" },
  { country: "Liberia", emoji: "🇱🇷", capital: "Monrovia" },
  { country: "Libya", emoji: "🇱🇾", capital: "Tripoli" },
  { country: "Liechtenstein", emoji: "🇱🇮", capital: "Vaduz" },
  { country: "Lithuania", emoji: "🇱🇹", capital: "Vilnius" },
  { country: "Luxembourg", emoji: "🇱🇺", capital: "Luxembourg City" },
  { country: "Macedonia", emoji: "🇲🇰", capital: "Skopje" },
  { country: "Madagascar", emoji: "🇲🇬", capital: "Antananarivo" },
  { country: "Malawi", emoji: "🇲🇼", capital: "Lilongwe" },
  { country: "Malaysia", emoji: "🇲🇾", capital: "Kuala Lumpur" },
  { country: "Maldives", emoji: "🇲🇻", capital: "Male" },
  { country: "Mali", emoji: "🇲🇱", capital: "Bamako" },
  { country: "Malta", emoji: "🇲🇹", capital: "Valletta" },
  { country: "Marshall Islands", emoji: "🇲🇭", capital: "Majuro" },
  { country: "Mauritania", emoji: "🇲🇷", capital: "Nouakchott" },
  { country: "Mauritius", emoji: "🇲🇺", capital: "Port Louis" },
  { country: "Mexico", emoji: "🇲🇽", capital: "Mexico City" },
  {
    country: "Micronesia, Federated States of",
    emoji: "🇫🇲",
    capital: "Palikir",
  },
  { country: "Moldova", emoji: "🇲🇩", capital: "Chișinău" },
  { country: "Monaco", emoji: "🇲🇨", capital: "Monaco" },
  { country: "Mongolia", emoji: "🇲🇳", capital: "Ulaanbaatar" },
  { country: "Montenegro", emoji: "🇲🇪", capital: "Podgorica" },
  { country: "Morocco", emoji: "🇲🇦", capital: "Rabat" },
  { country: "Mozambique", emoji: "🇲🇿", capital: "Maputo" },
  { country: "Myanmar (Burma)", emoji: "🇲🇲", capital: "Naypyidaw" },
  { country: "Namibia", emoji: "🇳🇦", capital: "Windhoek" },
  { country: "Nauru", emoji: "🇳🇷", capital: "Yaren District" },
  { country: "Nepal", emoji: "🇳🇵", capital: "Kathmandu" },
  { country: "Netherlands", emoji: "🇳🇱", capital: "Amsterdam" },
  { country: "New Zealand", emoji: "🇳🇿", capital: "Wellington" },
  { country: "Nicaragua", emoji: "🇳🇮", capital: "Managua" },
  { country: "Niger", emoji: "🇳🇪", capital: "Niamey" },
  { country: "Nigeria", emoji: "🇳🇬", capital: "Abuja" },
  { country: "Norway", emoji: "🇳🇴", capital: "Oslo" },
  { country: "Oman", emoji: "🇴🇲", capital: "Muscat" },
  { country: "Pakistan", emoji: "🇵🇰", capital: "Islamabad" },
  { country: "Palau", emoji: "🇵🇼", capital: "Ngerulmud" },
  { country: "Panama", emoji: "🇵🇦", capital: "Panama City" },
  { country: "Papua New Guinea", emoji: "🇵🇬", capital: "Port Moresby" },
  { country: "Paraguay", emoji: "🇵🇾", capital: "Asunción" },
  { country: "Peru", emoji: "🇵🇪", capital: "Lima" },
  { country: "Philippines", emoji: "🇵🇭", capital: "Manila" },
  { country: "Poland", emoji: "🇵🇱", capital: "Warsaw" },
  { country: "Portugal", emoji: "🇵🇹", capital: "Lisbon" },
  { country: "Qatar", emoji: "🇶🇦", capital: "Doha" },
  { country: "Romania", emoji: "🇷🇴", capital: "Bucharest" },
  { country: "Russia", emoji: "🇷🇺", capital: "Moscow" },
  { country: "Rwanda", emoji: "🇷🇼", capital: "Kigali" },
  { country: "Saint Kitts and Nevis", emoji: "🇰🇳", capital: "Basseterre" },
  { country: "Saint Lucia", emoji: "🇱🇨", capital: "Castries" },
  {
    country: "Saint Vincent and the Grenadines",
    emoji: "🇻🇨",
    capital: "Kingstown",
  },
  { country: "Samoa", emoji: "🇼🇸", capital: "Apia" },
  { country: "San Marino", emoji: "🇸🇲", capital: "San Marino" },
  { country: "Sao Tome and Principe", emoji: "🇸🇹", capital: "São Tomé" },
  { country: "Saudi Arabia", emoji: "🇸🇦", capital: "Riyadh" },
  { country: "Senegal", emoji: "🇸🇳", capital: "Dakar" },
  { country: "Serbia", emoji: "🇷🇸", capital: "Belgrade" },
  { country: "Seychelles", emoji: "🇸🇨", capital: "Victoria" },
  { country: "Sierra Leone", emoji: "🇸🇱", capital: "Freetown" },
  { country: "Singapore", emoji: "🇸🇬", capital: "Singapore" },
  { country: "Slovakia", emoji: "🇸🇰", capital: "Bratislava" },
  { country: "Slovenia", emoji: "🇸🇮", capital: "Ljubljana" },
  { country: "Solomon Islands", emoji: "🇸🇧", capital: "Honiara" },
  { country: "Somalia", emoji: "🇸🇴", capital: "Mogadishu" },
  { country: "South Africa", emoji: "🇿🇦", capital: "Pretoria" },
  { country: "South Sudan", emoji: "🇸🇸", capital: "Juba" },
  { country: "Spain", emoji: "🇪🇸", capital: "Madrid" },
  { country: "Sri Lanka", emoji: "🇱🇰", capital: "Sri Jayawardenepura Kotte" },
  { country: "Sudan", emoji: "🇸🇩", capital: "Khartoum" },
  { country: "Suriname", emoji: "🇸🇷", capital: "Paramaribo" },
  { country: "Swaziland", emoji: "🇸🇿", capital: "Mbabane" },
  { country: "Sweden", emoji: "🇸🇪", capital: "Stockholm" },
  { country: "Switzerland", emoji: "🇨🇭", capital: "Bern" },
  { country: "Syria", emoji: "🇸🇾", capital: "Damascsus" },
  { country: "Taiwan", emoji: "🇹🇼", capital: "Taipei" },
  { country: "Tajikistan", emoji: "🇹🇯", capital: "Dushanbe" },
  { country: "Tanzania", emoji: "🇹🇿", capital: "Dodoma" },
  { country: "Thailand", emoji: "🇹🇭", capital: "Bangkok" },
  { country: "Togo", emoji: "🇹🇬", capital: "Lome" },
  { country: "Tonga", emoji: "🇹🇴", capital: "Nuku'alofa" },
  { country: "Trinidad and Tobago", emoji: "🇹🇹", capital: "Port of Spain" },
  { country: "Tunisia", emoji: "🇹🇳", capital: "Tunis" },
  { country: "Turkey", emoji: "🇹🇷", capital: "Ankara" },
  { country: "Turkmenistan", emoji: "🇹🇲", capital: "Ashgabat" },
  { country: "Tuvalu", emoji: "🇹🇻", capital: "Funafuti" },
  { country: "Uganda", emoji: "🇺🇬", capital: "Kampala" },
  { country: "Ukraine", emoji: "🇺🇦", capital: "Kyiv" },
  { country: "United Arab Emirates", emoji: "🇦🇪", capital: "Abu Dhabi" },
  { country: "United Kingdom", emoji: "🇬🇧", capital: "London" },
  { country: "United States", emoji: "🇺🇸", capital: "Washington, D.C." },
  { country: "Uruguay", emoji: "🇺🇾", capital: "Montevideo" },
  { country: "Uzbekistan", emoji: "🇺🇿", capital: "Tashkent" },
  { country: "Vanuatu", emoji: "🇻🇺", capital: "Port Vila" },
  { country: "Vatican City", emoji: "🇻🇦", capital: "Vatican City" },
  { country: "Venezuela", emoji: "🇻🇪", capital: "Caracas" },
  { country: "Vietnam", emoji: "🇻🇳", capital: "Hanoi" },
  { country: "Yemen", emoji: "🇾🇪", capital: "Sana'a" },
  { country: "Zambia", emoji: "🇿🇲", capital: "Lusaka" },
  { country: "Zimbabwe", emoji: "🇿🇼", capital: "Harare" },
];

export const emojiQuiz = [
  {
    name: "Toy Story",
    emojis: "🧸🤠🚀🌟",
  },
  {
    name: "Finding Nemo",
    emojis: "🐠🔍🌊🐢",
  },
  {
    name: "Shrek",
    emojis: "🧟‍♂️👑🏰🐴",
  },
  {
    name: "Monsters, Inc.",
    emojis: "👹💼🚪😱",
  },
  {
    name: "The Incredibles",
    emojis: "🦸‍♀️🦸‍♂️✨💥",
  },
  {
    name: "Up",
    emojis: "🏠🎈📺👴",
  },
  {
    name: "Cars",
    emojis: "🚗💨🏁🔧",
  },
  {
    name: "Kung Fu Panda",
    emojis: "🐼🥋⛩️🥡",
  },
  {
    name: "Ratatouille",
    emojis: "🐀🍲🍴🇫🇷",
  },
  {
    name: "Wall-E",
    emojis: "🤖💡🌱🚀",
  },
  {
    name: "Ice Age",
    emojis: "🦥🦦🐘❄️",
  },
  {
    name: "The Polar Express",
    emojis: "🚂🎅🎄🎟️",
  },
  {
    name: "Madagascar",
    emojis: "🦁🦓🌴🇲🇬",
  },
  {
    name: "The Lion King",
    emojis: "🦁👑🦜🌅",
  },
  {
    name: "Harry Potter",
    emojis: "⚡🕶️🔮🏰",
  },
  {
    name: "The Princess Diaries",
    emojis: "👸📔👓🏰",
  },
  {
    name: "The Chronicles of Narnia",
    emojis: "🦁🗡️🚪❄️",
  },
  {
    name: "Aladdin",
    emojis: "🧞‍♂️🏺🐒🕌",
  },
  {
    name: "How to Train Your Dragon",
    emojis: "🐉👦📚🔥",
  },
  {
    name: "Coraline",
    emojis: "🌀🏠🎭🐱",
  },
  {
    name: "The Karate Kid",
    emojis: "👦🥋💪🏆",
  },
];
