import React from "react";
import { GluestackUIProvider, config } from "@gluestack-ui/themed";
import { NavigationContainer } from "@react-navigation/native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { StatusBar } from "expo-status-bar";

import QuizApp from "./src/pages/quiz";

export default function App() {
  return (
    <SafeAreaProvider>
      <NavigationContainer>
        <GluestackUIProvider
          config={config.theme}
          colorMode="dark"
        >
          <QuizApp />
        </GluestackUIProvider>
      </NavigationContainer>
      <StatusBar style={"light"} />
    </SafeAreaProvider>
  );
}
