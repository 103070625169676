import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { View, useWindowDimensions, ImageBackground } from "react-native";

import { useSafeAreaInsets } from "react-native-safe-area-context";
import ConfettiCannon from "react-native-confetti-cannon";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  Text,
  Button,
  Heading,
  ButtonText,
  VStack,
  useToast,
  Toast,
  ToastDescription,
  ToastTitle,
} from "@gluestack-ui/themed";

import { countries } from "../data/quiz";

const App = () => {
  const [correctPoints, setCorrectPoints] = useState(0);
  const [wrongPoints, setWrongPoints] = useState(0);
  const [quiz, setQuiz] = useState();
  const [loading, setLoading] = useState(true);

  const { top } = useSafeAreaInsets();

  const explosion = useRef();

  const handleSomeKindOfEvent = () => {
    explosion.current && explosion.current.start();
  };

  const newQuiz = () => {
    const quizData = getQuizData();
    console.log(quizData);
    setQuiz(quizData);
    setLoading(false);
  };

  useEffect(() => {
    newQuiz();
  }, []);

  const { width, height } = useWindowDimensions();
  const colors = [
    "#0000FF",
    "#00BFFF",
    "#1E90FF",
    "#87CEEB",
    "#7B68EE",
    "#6495ED",
    "#4682B4",
  ];
  // const colors = [
  //   "#98FB98",
  //   "#32CD32",
  //   "#3CB371",
  //   "#2E8B57",
  //   "#00FF00",
  //   "#7CFC00",
  // ];

  return (
    <ImageBackground
      style={{ flex: 1 }}
      source={require("../../assets/mum.jpg")}
    >
      <View
        style={{
          flex: 1,
          overflow: "hidden",
        }}
      >
        <View style={{ height: top }} />
        <Header
          correctPoints={correctPoints}
          wrongPoints={wrongPoints}
          quiz={quiz}
        />

        {!loading && (
          <View style={{ flex: 1 }}>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Heading style={{ fontSize: 100 }}>{quiz.emoji}</Heading>
            </View>
            <View
              style={{
                gap: 16,
                padding: 24,
              }}
            >
              {quiz.answers.map((possibleAnswer, index) => (
                <Button
                  key={index}
                  onPress={() => {
                    const rightAnswer = possibleAnswer === quiz.answer;
                    if (rightAnswer) {
                      setCorrectPoints(correctPoints + 1);
                      handleSomeKindOfEvent();
                    } else {
                      setWrongPoints(wrongPoints + 1);
                    }
                    newQuiz();
                  }}
                  sx={{
                    bg: "$white",
                    borderRadius: "$full",
                    ":hover": {
                      bg: "$blue100",
                      _text: {
                        color: "#0085FF",
                      },
                    },
                    ":pressed": {
                      bg: "$green600",
                      _text: {
                        color: "$white",
                      },
                    },
                  }}

                  // backgroundColor={"#full"}
                >
                  <ButtonText
                    color={"#0085FF"}
                    fontSize={20}
                  >
                    {possibleAnswer}
                  </ButtonText>
                </Button>
              ))}
            </View>
          </View>
        )}
        <ConfettiCannon
          colors={colors}
          count={250}
          origin={{ x: width / 2, y: height }}
          explosionSpeed={750}
          fallSpeed={500}
          fadeOut
          ref={explosion}
          autoStart={false}
        />
      </View>
    </ImageBackground>
  );
};

const ScoreIndicator = ({ score, wrong }) => {
  return (
    <View style={{ flexDirection: "row", gap: 4, alignItems: "center" }}>
      {wrong ? (
        <MaterialCommunityIcons
          name="sticker-remove-outline"
          size={32}
          color="#ef4444"
        />
      ) : (
        <MaterialCommunityIcons
          name="sticker-check-outline"
          size={32}
          color="#4ade80"
        />
      )}
      <Text
        size="xl"
        bold
        color={"white"}
      >
        {score}
      </Text>
    </View>
  );
};

ScoreIndicator.propTypes = {
  score: PropTypes.number,
  wrong: PropTypes.boolean,
};

const Header = ({ quiz, wrongPoints, correctPoints }) => {
  const toast = useToast();

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 16,
      }}
    >
      <Button
        onPress={() => {
          toast.show({
            duration: 1500,
            placement: "top left",
            render: ({ id }) => {
              return (
                <Toast
                  nativeId={id}
                  action="attention"
                  variant="solid"
                >
                  <VStack space="xs">
                    <ToastTitle>{quiz.country}</ToastTitle>
                    <ToastDescription>
                      {`Hint: ${quiz.country}'s capital city is the answer`}
                    </ToastDescription>
                  </VStack>
                </Toast>
              );
            },
          });
        }}
        sx={{
          flex: 1,
          padding: 16,
          marginRight: 16,
          backgroundColor: "transparent",
          borderColor: "white",
          borderWidth: 4,
          borderRadius: "$full",
          ":hover": {
            bg: "white",
            borderColor: "#ffe1fd",
            _text: {
              color: "#0085FF",
            },
          },
          ":pressed": {
            bg: "$green600",
            _text: {
              color: "$white",
            },
          },
        }}
      >
        <ButtonText
          color={"white"}
          fontSize={16}
        >
          Hint
        </ButtonText>
      </Button>

      <View style={{ flexDirection: "row", gap: 8 }}>
        <ScoreIndicator
          score={correctPoints}
          wrong={false}
        />
        <ScoreIndicator
          score={wrongPoints}
          wrong={true}
        />
      </View>
    </View>
  );
};

Header.propTypes = {
  correctPoints: PropTypes.any,
  quiz: PropTypes.shape({
    country: PropTypes.any,
  }),
  wrongPoints: PropTypes.any,
};

const extractData = (data) => {
  const capitalsArray = [];
  const emojisArray = [];
  const countriesArray = [];

  data.map((item) => {
    capitalsArray.push(item.capital);
    emojisArray.push(item.emoji);
    countriesArray.push(item.country);
  });

  return { capitalsArray, emojisArray, countriesArray };
};

const getQuizData = () => {
  const { capitalsArray, emojisArray, countriesArray } = extractData(countries);

  const index = Math.floor(Math.random() * countriesArray.length);
  const answer = capitalsArray[index];
  const emoji = emojisArray[index];
  const country = countriesArray[index];

  const tempArray = capitalsArray.filter((capital) => capital !== answer);

  const shuffledArray = tempArray.sort(() => 0.5 - Math.random());

  let answers = shuffledArray.slice(0, 3);

  const insertAt = Math.floor(Math.random() * (answers.length + 1));
  answers.splice(insertAt, 0, answer);

  return {
    answer,
    emoji,
    country,
    answers,
  };
};

export default App;
